import Hls from "hls.js";
import { hlsElementError } from "../error";
import { store } from "global";
import { headerNames, CacheType } from "shared/services";
import { log } from "services/logger";
import type { BrowserAudioItemModel } from "models/app/player/output";

export const createHlsElement = (browserAudio: BrowserAudioItemModel, url: string): Hls | null => {
    const { element } = browserAudio;
    if (!Hls.isSupported()) {
        log.error({ code: "web-210915-1236", msg: "hls not supported" });
        return null;
    }

    const hls = new Hls({
        xhrSetup: (xhr) => {
            if (store.getState().controlPanel.enableAudioPreload) {
                xhr.setRequestHeader(headerNames.cacheType, CacheType.Hls);
                xhr.setRequestHeader(headerNames.hlsCaller, "HlsJs");
            }
        },
        fragLoadingMaxRetry: 0,
        manifestLoadingMaxRetry: 0,
        levelLoadingMaxRetry: 0
    });

    hls.on(Hls.Events.ERROR, (event, data) => {
        if (browserAudio.disposed) return;
        if (!data.fatal) return;

        let logData: Record<string, unknown>;
        let message: string;
        try {
            const networkDetails = data.networkDetails as XMLHttpRequest | undefined;
            const logNetworkDetails = networkDetails ? { status: networkDetails.status, statusText: networkDetails.statusText, respomse: networkDetails.response } : undefined;

            message = data.err?.message ?? data.error?.message ?? data.error?.name ?? "undefined hls error message";
            logData = { reason: data.reason, err: data.err, error: data.error, details: data.details, type: data.type, url: data.url, networkDetails: logNetworkDetails };
        } catch (e) {
            message = "no data";
            logData = { error: "no data", e };
        }

        log.warn({ code: "web-210212-0949", msg: `hls audio error: ${data.type}, fatal: ${data.fatal}`, data: { event, ...logData } });
        hlsElementError(browserAudio, message);

        // switch (data.type) {
        //     case Hls.ErrorTypes.MEDIA_ERROR:
        //         if (data.fatal) hls.recoverMediaError();
        //         break;
        //     case Hls.ErrorTypes.NETWORK_ERROR:
        //         if (data.fatal) {
        //             // TODO must be disabled until we can distinguish between network and trackStream expired responses
        //             // eslint-disable-next-line @typescript-eslint/no-explicit-any
        //             // if ((data?.response as any)?.code === 0) {
        //             //     if (browserAudioItem.hlsStartLoadTimeout) return;
        //             //     browserAudioItem.hlsStartLoadTimeout = true;
        //             //     setTimeout(() => {
        //             //         browserAudioItem.hlsStartLoadTimeout = false;
        //             //         if (browserAudioItem.disposed) return;
        //             //         hls.startLoad();
        //             //     }, 1000);
        //             // } else {
        //             // }
        //         }
        //         break;
        //     case Hls.ErrorTypes.KEY_SYSTEM_ERROR:
        //     case Hls.ErrorTypes.MUX_ERROR:
        //     case Hls.ErrorTypes.OTHER_ERROR:
        //         break;
        // }
    });

    hls.attachMedia(element);
    hls.loadSource(url);

    return hls;
};
