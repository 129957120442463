import type { AlbumPreviewModel } from "./AlbumModel";
import type { ConnectionModel } from "./ConnectionModel";
import { createAlbumPreviewConnectionModel } from "./ConnectionModel";
import type { Recommendation, RecommendationOwner } from "./RecommendationModel";
import { createRecommendations } from "./RecommendationModel";
import type { RecommendationPreviewFragment_AlbumsRecommendation_, RecommendationPageFragment_AlbumsRecommendation_ } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type AlbumRecommendationPreviewData = RecommendationPreviewFragment_AlbumsRecommendation_;
type AlbumRecommendationPageData = RecommendationPageFragment_AlbumsRecommendation_;

export type AlbumRecommendationModel = AlbumRecommendationPreviewModel;
export type AlbumRecommendationPreviewModel = ({ type: DomainModelType.Preview } & AlbumRecommendation) | AlbumRecommendationPageModel;
export type AlbumRecommendationPageModel = { type: DomainModelType.Page } & AlbumRecommendationPage;

interface AlbumRecommendation extends Recommendation {
    contentType: ContentType.AlbumRecommendation;
}
interface AlbumRecommendationPage extends AlbumRecommendation {
    albums: ConnectionModel<AlbumPreviewModel> | null;
}

function createAlbumRecommendations(data: AlbumRecommendationPreviewData, owner: RecommendationOwner): AlbumRecommendation {
    return {
        ...createRecommendations(data, owner),
        contentType: ContentType.AlbumRecommendation
    };
}
export function createAlbumRecommendationsPreviewModel(data: AlbumRecommendationPreviewData, owner: RecommendationOwner): AlbumRecommendationPreviewModel {
    return {
        ...createAlbumRecommendations(data, owner),
        type: DomainModelType.Preview
    };
}
export function createAlbumRecommendationsPageModel(data: AlbumRecommendationPageData, owner: RecommendationOwner): AlbumRecommendationPageModel {
    return {
        ...createAlbumRecommendations(data, owner),
        type: DomainModelType.Page,
        albums: data.albums ? createAlbumPreviewConnectionModel(data.albums) : null
    };
}
