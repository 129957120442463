import type { ComponentChild, ComponentChildren } from "preact";
import { h } from "preact";
import { useRef } from "preact/compat";
import "./PreviewRow.scss";
import type { PreviewProps } from "./PreviewProps";
import { PreviewAction } from "./PreviewProps";
import { usePreviewScrollToHighlight } from "./usePreviewScrollToHighlight";
import { DropZone } from "../dropZone";
import { PreviewRowTestLocator } from "global/constants";
import type { Multiselect } from "services/selection";
import type { DraggableItemModel } from "models/app";
import { useLinkContextFromPreview } from "components/shared/hooks";
import { Button, ButtonDesign } from "components/atoms/controls/button";

interface Props extends PreviewProps {
    number?: ComponentChild;
    columns: number;
    disabled?: boolean;
    buttons?: ComponentChildren;
    expandContent?: ComponentChildren;
    highlight?: boolean;
    multiselect?: Multiselect;
    allowDrop?: (item: DraggableItemModel) => boolean;
}

export const PreviewRow = ({
    activated,
    available = true,
    editable,
    buttons,
    className,
    columns,
    context,
    disabled = false,
    draggableItem,
    dropProps,
    image,
    links,
    mainAction,
    number,
    onAction,
    onContextMenu,
    resourceType: contentType,
    expandContent,
    highlight,
    multiselect,
    allowDrop
}: Props) => {
    const ref = useRef(null);

    const link = useLinkContextFromPreview(context);
    usePreviewScrollToHighlight({ ref, highlight });

    return (
        <div className={`previewRow weG --type-${contentType} --columns-${columns} ${className ?? ""}  --available-${available} --editable-${editable} --highlight-${highlight}`}>
            <Button
                ref={ref}
                activated={activated}
                allowModifierKeys={!!multiselect}
                data-cy={PreviewRowTestLocator(contentType)}
                design={ButtonDesign.PlayableRow}
                disabled={disabled}
                draggableItem={available ? draggableItem : undefined}
                link={mainAction === PreviewAction.Navigate ? link : undefined}
                onClick={available && onAction ? onAction : undefined}
                onContextMenu={onContextMenu}>
                {available && dropProps?.onDrop && <DropZone allowDrop={allowDrop} onDropFn={dropProps.onDrop} />}
                <figure>
                    {number}
                    {image}
                    <figcaption>
                        {links.map((text, index) => (
                            <div key={index}>{text}</div>
                        ))}
                    </figcaption>
                    {buttons}
                </figure>
            </Button>
            {expandContent && <div className="expand">{expandContent}</div>}
        </div>
    );
};
