import { h } from "preact";
import { useCallback } from "preact/hooks";
import { Link } from "../Link";
import { useTranslations } from "global/config";
import { TestLocator } from "global/constants";
import { useDisplayLoggedIn } from "services/user";
import { IconName } from "components/atoms/icon";
import { useLinkAndPreviewContext, useSectionContext } from "components/shared/hooks";
import { ContentType } from "models/ModelType";
import { navigation } from "services/navigation";

export const AddProfileLink = () => {
    const section = useSectionContext(ContentType.ProfilesPage, null, null, null, null);
    const link = useLinkAndPreviewContext(ContentType.ProfilesPage, null, section, null);
    link.url = navigation.templates.addProfilesPage(); // todo

    const loggedIn = useDisplayLoggedIn();
    const translations = useTranslations();

    const onAddProfile = useCallback(() => {
        // todo
    }, []);

    if (!loggedIn) return null;

    return (
        <Link closeOnClick onClick={onAddProfile} link={link} tooltip={translations.NavBarLogin} icon={IconName.Plus16} testLocator={TestLocator.AccountMenuAddProfileButton} className="iconcircle">
            {translations.AddProfile}
        </Link>
    );
};
