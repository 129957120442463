import type { ConnectionModel } from "./ConnectionModel";
import { createTrackPreviewConnectionModel } from "./ConnectionModel";
import type { PlayQueuePlayableModelInterface } from "./PlayQueuePlayableModel";
import type { Recommendation, RecommendationOwner } from "./RecommendationModel";
import { createRecommendations } from "./RecommendationModel";
import type { TrackPreviewModel } from "./TrackModel";
import type {
    RecommendationPreviewFragment_TracksRecommendation_,
    RecommendationPlayFragment_TracksRecommendation_,
    RecommendationPageFragment_TracksRecommendation_
} from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type TrackRecommendationPreviewData = RecommendationPreviewFragment_TracksRecommendation_;
type TrackRecommendationPlayData = RecommendationPlayFragment_TracksRecommendation_;
type TrackRecommendationPageData = RecommendationPageFragment_TracksRecommendation_;

interface TrackRecommendation extends Recommendation, PlayQueuePlayableModelInterface {
    contentType: ContentType.TrackRecommendation;
}
interface TrackRecommendationPlay extends TrackRecommendation {
    tracks: ConnectionModel<TrackPreviewModel> | null;
}
type TrackRecommendationPage = TrackRecommendationPlay;

export type TrackRecommendationModel = TrackRecommendationPreviewModel;
export type TrackRecommendationPreviewModel = ({ type: DomainModelType.Preview } & TrackRecommendation) | TrackRecommendationPlayModel;
export type TrackRecommendationPlayModel = ({ type: DomainModelType.Play } & TrackRecommendationPlay) | TrackRecommendationPageModel;
export type TrackRecommendationPageModel = { type: DomainModelType.Page } & TrackRecommendationPage;

function createTrackRecommendations(data: TrackRecommendationPreviewData, owner: RecommendationOwner): TrackRecommendation {
    return {
        ...createRecommendations(data, owner),
        contentType: ContentType.TrackRecommendation,
        trackCount: null
    };
}
export function createTrackRecommendationsPreviewModel(data: TrackRecommendationPreviewData, owner: RecommendationOwner): TrackRecommendationPreviewModel {
    return {
        ...createTrackRecommendations(data, owner),
        type: DomainModelType.Preview
    };
}
export function createTrackRecommendationsPlayModel(data: TrackRecommendationPlayData, owner: RecommendationOwner): TrackRecommendationPlayModel {
    return {
        ...createTrackRecommendations(data, owner),
        type: DomainModelType.Play,
        tracks: data.tracks ? createTrackPreviewConnectionModel(data.tracks, 0) : null
    };
}
export function createTrackRecommendationsPageModel(data: TrackRecommendationPageData, owner: RecommendationOwner): TrackRecommendationPageModel {
    return {
        ...createTrackRecommendations(data, owner),
        type: DomainModelType.Page,
        tracks: data.tracks ? createTrackPreviewConnectionModel(data.tracks, 0) : null
    };
}
