import type { ComponentChildren } from "preact";
import { h, Fragment } from "preact";
import { forwardRef, useState } from "preact/compat";
import { Link } from "preact-router/match";
import "./Button.scss";
import { dispatch } from "global";
import { PAGE_CLICK, SET_USER_DRAGGING } from "global/actions";
import type { TestLocatorValue } from "global/constants";
import { log, DefaultLogMessage } from "services/logger/initLoggerService";
import { navigateForward } from "services/navigationStack/navigationStack";
import { getIcon } from "services/resource";
import type { DraggableItemModel } from "models/app";
import type { LinkContextModel } from "models/app/resourceContextModel";
import type { IconName } from "components/atoms/icon";
import { IconSize, Icon } from "components/atoms/icon";

export interface ButtonInline {
    top?: boolean;
    right?: boolean;
    bottom?: boolean;
    left?: boolean;
}

export enum ButtonDesign {
    ContextMenu = "contextMenu",
    ContextMenuBottom = "contextMenuBottom",
    Cover = "cover",
    DefaultBig = "defaultBig",
    DefaultMicro = "defaultMicro",
    DefaultSmall = "defaultSmall",
    LightBig = "lightBig",
    LightBrandBig = "lightBrandBig",
    LightBrandSmall = "lightBrandSmall",
    LightMicro = "lightMicro",
    LightSmall = "lightSmall",
    NavBarLink = "navBarLink",
    None = "none",
    PlayableLinkMaxiPrimary = "playableLinkMaxiPrimary",
    PlayableLinkMaxiSecondary = "playableLinkMaxiSecondary",
    PlayableLinkPrimary = "playableLinkPrimary",
    PlayableLinkPrimary2Lines = "playableLinkPrimary2Lines",
    PlayableLinkSecondary = "playableLinkSecondary",
    PlayableRow = "playableRow",
    PlayerPrimary = "playerPrimary",
    PlayerSecondary = "playerSecondary",
    PrimaryBig = "primaryBig",
    PrimaryMicro = "primaryMicro",
    PrimarySmall = "primarySmall",
    SecondaryBig = "secondaryBig",
    SecondarySmall = "secondarySmall",
    SideBarLinkBig = "sideBarLinkBig",
    SideBarLinkBigMinimal = "sideBarLinkBigMinimal",
    SideBarLinkMedium = "sideBarLinkMedium",
    TextBrandInline = "textBrandInline",
    TextBrandMedium = "textBrandMedium",
    TextBrandSmall = "textBrandSmall",
    TextInline = "textInline",
    TextInlineMedium = "textInlineMedium",
    TextSmall = "textSmall",
    ProfileGiant = "profileGiant"
}

export function getIconSize(design: ButtonDesign): IconSize {
    switch (design) {
        case ButtonDesign.DefaultMicro:
        case ButtonDesign.DefaultSmall:
        case ButtonDesign.LightBrandSmall:
        case ButtonDesign.LightMicro:
        case ButtonDesign.LightSmall:
        case ButtonDesign.PrimaryMicro:
        case ButtonDesign.PrimarySmall:
        case ButtonDesign.SecondarySmall:
        case ButtonDesign.TextBrandSmall:
        case ButtonDesign.TextSmall:
        case ButtonDesign.ContextMenu:
            return IconSize.Small;
        case ButtonDesign.ContextMenuBottom:
        case ButtonDesign.Cover:
        case ButtonDesign.DefaultBig:
        case ButtonDesign.LightBig:
        case ButtonDesign.LightBrandBig:
        case ButtonDesign.NavBarLink:
        case ButtonDesign.None:
        case ButtonDesign.PlayableLinkMaxiPrimary:
        case ButtonDesign.PlayableLinkMaxiSecondary:
        case ButtonDesign.PlayableLinkPrimary2Lines:
        case ButtonDesign.PlayableLinkPrimary:
        case ButtonDesign.PlayableLinkSecondary:
        case ButtonDesign.PlayableRow:
        case ButtonDesign.PlayerSecondary:
        case ButtonDesign.PrimaryBig:
        case ButtonDesign.SecondaryBig:
        case ButtonDesign.SideBarLinkBig:
        case ButtonDesign.SideBarLinkBigMinimal:
        case ButtonDesign.SideBarLinkMedium:
        case ButtonDesign.TextBrandInline:
        case ButtonDesign.TextBrandMedium:
        case ButtonDesign.TextInline:
        case ButtonDesign.TextInlineMedium:
            return IconSize.Default;
        case ButtonDesign.PlayerPrimary:
        case ButtonDesign.ProfileGiant:
            return IconSize.Big;
    }
}

interface Props {
    activated?: boolean;
    allowModifierKeys?: boolean;
    children?: ComponentChildren;
    className?: string;
    design?: ButtonDesign;
    disabled?: boolean;
    draggableItem?: () => DraggableItemModel | null;
    externalUrl?: string;
    hover?: boolean;
    icon?: IconName;
    inheritStates?: boolean;
    inline?: ButtonInline;
    link?: LinkContextModel;
    onClick?: (ev: MouseEvent) => void;
    onContextMenu?: (ev: MouseEvent) => void;
    onPointerEnter?: (ev: PointerEvent) => void;
    onPointerLeave?: (ev: PointerEvent) => void;
    round?: boolean;
    tabIndex?: number;
    testLocator?: TestLocatorValue;
    ariaLabel?: string;
    toggled?: boolean;
    useIcon?: boolean;
    useText?: boolean;
}

interface ContentProps {
    children?: ComponentChildren;
    design: ButtonDesign;
    icon?: IconName;
}

interface ElementProps {
    class?: string;
    disabled?: boolean;
    draggable: boolean;
    onClick?: (ev: MouseEvent) => void;
    onContextMenu?: (ev: MouseEvent) => void;
    onDragEnd?: (ev: DragEvent) => void;
    onDragStart?: (ev: DragEvent) => void;
    onMouseDown?: (ev: MouseEvent) => void;
    onMouseUp?: (ev: MouseEvent) => void;
    onPointerEnter?: (ev: PointerEvent) => void;
    onPointerLeave?: (ev: PointerEvent) => void;
    tabIndex?: number;
    title?: string;
}

interface ButtonProps {
    elementProps: ElementProps;
    children?: ComponentChildren;
    testLocator?: TestLocatorValue;
    ariaLabel?: string;
}

interface LinkProps extends ButtonProps {
    link: LinkContextModel;
}

interface ExternalLinkProps extends ButtonProps {
    url: string;
}

enum MouseButton {
    Main = 0, // usually the left button or the un-initialized state
    Auxiliary = 1, // usually the wheel button or the middle button (if present)
    Secondary = 2, // usually the right button
    Fourth = 3, // typically the Browser Back button
    Fifth = 4 // typically the Browser Forward button
}

let preventAction = false;
let preventActionTimeoutId: number | null;

function setPreventAction() {
    if (preventActionTimeoutId) {
        clearInterval(preventActionTimeoutId);
        preventActionTimeoutId = null;
    }

    preventAction = true;
    preventActionTimeoutId = window.setTimeout(() => {
        preventActionTimeoutId = null;
        preventAction = false;
    }, 0);
}

export const Button = forwardRef<HTMLElement, Props>((props, ref) => {
    const {
        activated = false,
        allowModifierKeys = false,
        children: propChildren,
        className,
        design = ButtonDesign.DefaultBig,
        disabled,
        draggableItem: draggableItemFn,
        hover = false,
        icon: propIcon,
        inheritStates = false,
        inline,
        link,
        onContextMenu,
        onPointerEnter,
        onPointerLeave,
        testLocator,
        ariaLabel,
        toggled = false,
        useIcon: linkIcon,
        useText: linkText
    } = props;
    let { onClick: propOnClick, externalUrl, round, tabIndex } = props;

    if (disabled) {
        if (link) link.url = undefined;
        propOnClick = undefined;
        externalUrl = undefined;
    }

    const children = linkText ? link?.text : propChildren;
    const icon = linkIcon ? (link?.type != null ? getIcon(link.type) ?? undefined : undefined) : propIcon;

    const hasPrimaryAction = link?.url != null || propOnClick != null || externalUrl != null;
    const hasContextMenuAction = onContextMenu != null;

    if (!hasPrimaryAction || disabled) tabIndex = -1;
    if (round == undefined) round = icon != null && children == null;

    const [active, setActive] = useState<number | null>(null);

    const onMouseDown = (ev: MouseEvent) => {
        setActive(ev.button);
    };

    const onMouseUp = () => {
        setActive(null);
    };

    const onClick = (ev: MouseEvent) => {
        if (propOnClick == null && link == null) return;

        if (preventAction) return;
        setPreventAction();
        if (!allowModifierKeys) {
            if (ev.ctrlKey) return;
            if (ev.metaKey) return;
            if (ev.shiftKey) return;
            if (ev.altKey) return;
        }
        if (propOnClick) propOnClick(ev);
        if (link) navigateForward(link);

        dispatch({ type: PAGE_CLICK, payload: { ev } });

        ev.stopPropagation();
        ev.preventDefault();
    };

    const contextMenu = (ev: MouseEvent) => {
        const isLongPress = () => ev.button === MouseButton.Main;

        if (isLongPress() && !onContextMenu) {
            onClick(ev);
            return;
        }

        if (onContextMenu != null) {
            ev.stopPropagation();
            ev.preventDefault();
            onContextMenu(ev);
        }

        dispatch({ type: PAGE_CLICK, payload: { ev } });
    };

    const pointerEnter = (ev: PointerEvent) => {
        if (onPointerEnter == null) return;
        ev.stopPropagation();
        ev.preventDefault();
        onPointerEnter(ev);
    };

    const pointerLeave = (ev: PointerEvent) => {
        setActive(null);
        if (onPointerLeave == null) return;
        ev.stopPropagation();
        ev.preventDefault();
        onPointerLeave(ev);
    };

    const onDragStart = (e: DragEvent) => {
        if (draggableItemFn == null) {
            log.error({ code: "web-210511-1510", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }
        const draggableItem = draggableItemFn();
        if (draggableItem == null) {
            log.error({ code: "web-210528-1502", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }
        e.stopPropagation();
        if (!e.dataTransfer) return;
        setTimeout(() => {
            dispatch({ type: SET_USER_DRAGGING, payload: { item: draggableItem } });
        }, 10);
        const stringifiedItem = JSON.stringify(draggableItem);
        e.dataTransfer.setData("dragItem", stringifiedItem);

        const node = document.getElementById("dragLabel");
        if (node == null) return;
        node.innerText = draggableItem.title ?? "";
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.dropEffect = "move";
        e.dataTransfer.setDragImage(node, 0, 0);
    };

    const onDragEnd = () => {
        dispatch({ type: SET_USER_DRAGGING, payload: { item: null } });
    };

    let inlineClassNames = "";
    if (inline?.top === true) inlineClassNames += "--inlineTop-true ";
    if (inline?.right === true) inlineClassNames += "--inlineRight-true ";
    if (inline?.bottom === true) inlineClassNames += "--inlineBottom-true ";
    if (inline?.left === true) inlineClassNames += "--inlineLeft-true ";
    const draggable = !!draggableItemFn;
    const elementProps: ElementProps = {
        class: `button WjK ${hover ? "--hover-true" : ""
            } --hasPrimaryAction-${hasPrimaryAction} --hasContextMenuAction-${hasContextMenuAction} --active-${active} --toggled-${toggled} --activated-${activated} --design-${design} --round-${round} ${inheritStates ? "--inheritStates-true" : ""
            } ${className ?? ""} ${inlineClassNames} --draggable-${draggable}`,
        onClick,
        onContextMenu: contextMenu,
        onPointerEnter: pointerEnter,
        onPointerLeave: pointerLeave,
        onMouseDown,
        onMouseUp,
        disabled,
        draggable,
        onDragEnd: draggable ? onDragEnd : undefined,
        onDragStart: draggable ? onDragStart : undefined,
        tabIndex
    };

    const content = (
        <Content icon={icon} design={design}>
            {children}
        </Content>
    );

    if (link != null)
        return (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            <LinkElement ref={ref as any} link={link} elementProps={elementProps} testLocator={testLocator}>
                {content}
            </LinkElement>
        );
    else if (externalUrl != null)
        return (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            <ExternalLinkElement ref={ref as any} url={externalUrl} elementProps={elementProps} testLocator={testLocator}>
                {content}
            </ExternalLinkElement>
        );
    else
        return (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            <ButtonElement ref={ref as any} elementProps={elementProps} testLocator={testLocator} ariaLabel={ariaLabel}>
                {content}
            </ButtonElement>
        );
});

const ButtonElement = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    return (
        <button ref={ref} {...props.elementProps} data-cy={props.testLocator} aria-label={props.ariaLabel}>
            {props.children}
        </button>
    );
});

const LinkElement = forwardRef<HTMLElement, LinkProps>((props, ref) => {
    return (
        <Link
            ref={(linkRef) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const element = (linkRef as any)?.base;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if (ref && element) (ref as any).current = element;
            }}
            activeClassName="--location-true"
            {...props.elementProps}
            href={props.link.url}
            data-cy={props.testLocator}>
            {props.children}
        </Link>
    );
});

const ExternalLinkElement = forwardRef<HTMLAnchorElement, ExternalLinkProps>((props, ref) => {
    return (
        <a {...props.elementProps} href={props.url} ref={ref} data-cy={props.testLocator} target="_blank" rel="noreferrer noopener">
            {props.children}
        </a>
    );
});

const Content = ({ children, icon, design }: ContentProps) => {
    const childs = [children].flat();
    if (icon != null) childs.unshift(<Icon icon={icon} size={getIconSize(design)} />);

    return (
        <Fragment>
            {childs.map((child) => {
                switch (typeof child) {
                    case "string":
                    case "number":
                    case "bigint":
                        return <div className="text">{child}</div>;
                    default:
                        return child;
                }
            })}
        </Fragment>
    );
};
