import { UserProfileModel } from "models/app";
import type { AnalyticsData } from "../analyticsData";
import { combineAnalyticsData } from "../analyticsData";
import { convertAnalyticsAppProperties, getAnalyticsAppProperties } from "../properties/app/appProperties";
import { getAnalyticsUserProperties } from "../properties/user";
import { PROFILE_CREATED, PROFILE_UPDATED, PROFILE_DELETED } from "global/actions";
import { getCreateProfileActionProperties, getDeleteProfileActionProperties, getEditProfileActionProperties } from "../properties/user/userProfileProperties";

export async function getCreateProfileEvent(profile: UserProfileModel): Promise<AnalyticsData> {
    const data = await combineAnalyticsData(
        convertAnalyticsAppProperties(getAnalyticsAppProperties()),
        getAnalyticsUserProperties(),
        getCreateProfileActionProperties(profile)
    );
    return data;
}

export async function getEditProfileEvent(profile: UserProfileModel, oldProfile: UserProfileModel): Promise<AnalyticsData> {
    const data = await combineAnalyticsData(
        convertAnalyticsAppProperties(getAnalyticsAppProperties()),
        getAnalyticsUserProperties(),
        getEditProfileActionProperties(profile, oldProfile)
    );
    return data;
}

export async function getDeleteProfileEvent(profile: UserProfileModel): Promise<AnalyticsData> {
    const data = await combineAnalyticsData(
        convertAnalyticsAppProperties(getAnalyticsAppProperties()),
        getAnalyticsUserProperties(),
        getDeleteProfileActionProperties(profile)
    );
    return data;
}

