import { h } from "preact";
import type { ComponentChild } from "preact";
import { useCallback, useState } from "preact/hooks";
import "./ResourcePreviewRow.scss";
import { PlayablePreviewRowCover } from "./PlayablePreviewRowCover";
import { ResourcePreviewRowCover } from "./ResourcePreviewRowCover";
import { useResourcePreviewExpandContent } from "./ResourcePreviewRowExpandContent";
import { PreviewDisplayType, usePreviewAction } from "..";
import { ResourceTextPart, ResourceTextDesign } from "../../resource";
import { PreviewLinks, PreviewTexts } from "../PreviewLinks";
import { PreviewAction } from "../PreviewProps";
import { PreviewRow } from "../PreviewRow";
import { isPlayable } from "services/playable";
import { getShowRowNumber, getShowRowCover, getResourceMainAction } from "services/resource";
import type { Multiselect } from "services/selection";
import { updateSelection, isSelected } from "services/selection";
import type { DragProps, DropProps } from "models/app";
import { createDraggablePlayableModel } from "models/app";
import type { PreviewContextModel, SectionContextModel } from "models/app/resourceContextModel";
import type { PlayablePreviewModel, ResourceContentType, ResourcePreviewModel } from "models/domain";
import type { NotificationConversion } from "models/domain/NotificationModel";
import { ContentType } from "models/ModelType";
import { useLinkContext, useOnRightClickContextMenu, usePlayablePropertiesFromState, usePreviewContext } from "components/shared/hooks";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { ButtonMore } from "components/atoms/controls/button/buttons";
import type { CoverSize } from "components/atoms/cover";
import { IconName } from "components/atoms/icon";
import { PlayableSoundbars, SoundbarStyle } from "components/atoms/soundbars";

interface Props {
    context: SectionContextModel;
    customIcon?: IconName;
    customIconAction?: (resource: ResourcePreviewModel) => void;
    dragProps: DragProps;
    dropProps: DropProps | null;
    onAction?: (action: PreviewAction, preview: PreviewContextModel, conversion: NotificationConversion | null) => void;
    rank: number;
    resource: ResourcePreviewModel;
    size: CoverSize;
    width: number | null;
    disableLazyLoad: boolean;
    highlight?: boolean;
    multiselect?: Multiselect;
    locked?: boolean;
}

export const ResourcePreviewRow = ({
    context: sectionContext,
    customIcon,
    customIconAction,
    dragProps,
    dropProps,
    onAction: onActionProp,
    rank,
    resource,
    size,
    width,
    disableLazyLoad,
    highlight,
    multiselect,
    locked
}: Props) => {
    const [moreButtonIsOpen, setMoreButtonIsOpen] = useState(false);

    const context = usePreviewContext(resource.contentType, resource, sectionContext, rank);
    const { hasContextMenu, onContextMenu, disableContextMenu, editable, available, moreOpen } = useOnRightClickContextMenu(
        context,
        resource,
        sectionContext,
        moreButtonIsOpen,
        multiselect
    );
    const expandContent = useResourcePreviewExpandContent({ resource, preview: context, onAction: onActionProp });

    const columns = getPreviewRowColumnCount(width, resource.contentType, context);
    const mainAction = getResourceMainAction(resource.contentType, resource);
    const { onAction, onMainAction } = usePreviewAction({ preview: context, onAction: onActionProp, resource, mainAction, expandAction: expandContent.toggle });
    const onNavigate = useCallback(() => onActionProp?.(PreviewAction.Navigate, context, null), [onActionProp, context]);
    const onCustomAction = useCallback(() => customIconAction?.(resource), [customIconAction, resource]);

    const getAllLinks = resource.contentType === ContentType.LiveRadioTrack ? PreviewTexts : PreviewLinks;
    const allLinks = getAllLinks({
        resource,
        resourceType: resource.contentType,
        context,
        displayType: PreviewDisplayType.Row,
        onNavigate,
        disabled: !available
    });
    const links = getVisibleLinks(allLinks, columns, resource.contentType);

    const { number, image } = isPlayable(resource)
        ? PlayableRowNumberAndCover({ available, playable: resource, context, number: rank, size, width, onAction, disableLazyLoad })
        : RowNumberAndCover({ resource, context, number: rank, size, width, disableLazyLoad });

    const menuButton =
        hasContextMenu &&
        RightButton({
            disabled: disableContextMenu || locked,
            resource,
            context,
            mainAction,
            setMoreOpen: setMoreButtonIsOpen,
            onNavigate
        });

    const customButton = !!customIcon && <Button icon={customIcon} onClick={onCustomAction} disabled={locked} />;

    const draggableItem = useCallback(() => createDraggablePlayableModel(resource, dragProps.dragSourceId, context), [resource, dragProps.dragSourceId, context]);

    const onClick = useCallback(
        (ev: MouseEvent) => {
            if (!multiselect) return onMainAction();
            updateSelection(multiselect, resource, ev.ctrlKey, ev.shiftKey);
        },
        [multiselect, onMainAction, resource]
    );

    const isActivated = moreOpen || isSelected(multiselect, resource);
    const isMultiselecting = multiselect && multiselect.state.selectedItems.length > 1;

    return (
        <PreviewRow
            multiselect={multiselect}
            editable={editable}
            available={available}
            activated={isActivated}
            buttons={[menuButton, expandContent.button, customButton]}
            className={`resourcePreviewRow Ecc --mainAction-${mainAction}`}
            columns={columns}
            context={context}
            draggableItem={isMultiselecting ? undefined : draggableItem}
            dropProps={dropProps}
            image={image}
            highlight={highlight}
            links={links}
            number={number}
            onAction={onClick}
            mainAction={mainAction}
            onContextMenu={locked ? undefined : onContextMenu}
            resourceType={resource.contentType}
            expandContent={expandContent.content}
        />
    );
};

interface RowNumberAndCoverProps {
    resource: ResourcePreviewModel;
    context: PreviewContextModel;
    number: number;
    size: CoverSize;
    width: number | null;
    onNavigate?: () => void;
    disableLazyLoad: boolean;
}

const RowNumberAndCover = ({ resource, context, number: propNumber, size, disableLazyLoad }: RowNumberAndCoverProps) => {
    const showNumber = getShowRowNumber(resource.contentType, context);
    const showCover = getShowRowCover(context);

    let number: h.JSX.Element | undefined;
    let image: h.JSX.Element | undefined;

    if (showNumber) number = <NumberCell number={propNumber} />;
    if (showCover) image = <ResourcePreviewRowCover context={context} size={size} disableLazyLoad={disableLazyLoad} />;

    return { number, image };
};

interface PlayableRowNumberAndCoverProps {
    available: boolean;
    context: PreviewContextModel;
    number: number;
    onAction: (action: PreviewAction) => void;
    playable: PlayablePreviewModel;
    size: CoverSize;
    width: number | null;
    disableLazyLoad: boolean;
}

const PlayableRowNumberAndCover = ({ playable, context, number: propNumber, size, onAction, available, disableLazyLoad }: PlayableRowNumberAndCoverProps) => {
    const isTrack = playable.contentType === ContentType.TrackPlayable;
    const isLoaded = usePlayablePropertiesFromState(playable).isLoaded;

    const soundbars = isTrack && isLoaded;

    const showNumber = getShowRowNumber(playable.contentType, context);
    const showCover = getShowRowCover(context);

    let number: h.JSX.Element | undefined;
    let image: h.JSX.Element | undefined;

    if (showNumber) {
        if (!showCover && soundbars) number = <NumberWithSoundBarsCell playable={playable} />;
        else number = <NumberCell number={propNumber} />;
    }
    if (showCover)
        image = <PlayablePreviewRowCover available={available} playable={playable} context={context} size={size} onAction={onAction} disableLazyLoad={disableLazyLoad} />;

    return { number, image };
};

const NumberWithSoundBarsCell = ({ playable }: { playable: PlayablePreviewModel }) => {
    return <div className="number">{<PlayableSoundbars playable={playable} style={SoundbarStyle.Default} />}</div>;
};

const NumberCell = ({ number }: { number: number }) => {
    return (
        <div className="number">
            <ResourceTextPart design={ResourceTextDesign.SecondarySmall}>{(number + 1).toFixed()}</ResourceTextPart>
        </div>
    );
};

interface RightButtonProps {
    resource: ResourcePreviewModel;
    context: PreviewContextModel;
    mainAction: PreviewAction;
    setMoreOpen: (open: boolean) => void;
    onNavigate?: () => void;
    disabled?: boolean;
}

const RightButton = ({ resource, disabled, context, mainAction, setMoreOpen, onNavigate }: RightButtonProps) => {
    const hasNavigate = mainAction === PreviewAction.Navigate && context.section?.page?.type === ContentType.Search;
    const navigateLink = useLinkContext(resource.contentType, resource, context);
    if (hasNavigate) return <Button onClick={onNavigate} link={navigateLink} icon={IconName.ArrowRightAndroid} design={ButtonDesign.DefaultBig} inline={{ right: true }} />;

    return (
        <ButtonMore
            resource={resource}
            context={context}
            design={ButtonDesign.DefaultBig}
            icon={IconName.Meatballs}
            inline={{ right: true }}
            onOpen={setMoreOpen}
            disabled={disabled}
        />
    );
};

function getPreviewRowColumnCount(width: number | null, resourceType: ResourceContentType, context: PreviewContextModel) {
    const showNumber = getShowRowNumber(resourceType, context);
    const showCover = getShowRowCover(context);

    let usedWidth = 16 + 48 - 12; // margin + more button etc.
    if (showNumber) usedWidth += 12 + 16;
    if (showCover) usedWidth += 48 + 16;

    let columns = width != null ? Math.floor((width - usedWidth) / 250) : 1;
    if (columns < 1) columns = 1;
    if (columns > 4) columns = 4;

    return columns;
}

export function getVisibleLinks(links: ComponentChild[], columns: number, contentType: ContentType) {
    // tood: calc maxLinks instead
    const maxInFirstColumn = getMaxInFirstColumn(contentType);
    const maxInAllColumns = contentType == ContentType.Playlist ? columns + 1 : columns;
    const maxLinks = columns === 1 ? maxInFirstColumn : maxInAllColumns;
    if (links.length > maxLinks) links.splice(maxLinks);
    return links;
}

function getMaxInFirstColumn(contentType: ContentType): number {
    switch (contentType) {
        case ContentType.Playlist:
        case ContentType.Notification:
            return 3;
        case ContentType.Album:
            return 4;
        default:
            return 2;
    }
}
