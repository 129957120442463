import { PROFILE_CREATED, PROFILE_DELETED, PROFILE_UPDATED } from "global/actions";
import { UserProfileModel } from "models/app";
import { createAnalyticsData, IncrementAnalyticsProp, SetAnalyticsProp, SetFirstAnalyticsProp } from "../../analyticsData";

export function getCreateProfileActionProperties(profile: UserProfileModel) {
    const data = createAnalyticsData();

    SetFirstAnalyticsProp(data, "user", "FirstCreateProfile", new Date());
    SetAnalyticsProp(data, "user", "LastCreateProfile", new Date());
    IncrementAnalyticsProp(data, "user", "NumberOfCreateProfile");

    SetAnalyticsProp(data, "event", "ProfileColor", profile.color as any);
    SetAnalyticsProp(data, "event", "ProfileName", profile.title as any);

    return data;
}

export function getEditProfileActionProperties(profile: UserProfileModel, oldProfile: UserProfileModel) {
    const data = createAnalyticsData();

    SetFirstAnalyticsProp(data, "user", "FirstEditProfile", new Date());
    SetAnalyticsProp(data, "user", "LastEditProfile", new Date());
    IncrementAnalyticsProp(data, "user", "NumberOfEditProfile");

    SetAnalyticsProp(data, "event", "NameChanged", profile.title !== oldProfile.title);
    SetAnalyticsProp(data, "event", "ColorChanged", profile.color !== oldProfile.color);

    SetAnalyticsProp(data, "event", "ProfileColor", profile.color as any);
    SetAnalyticsProp(data, "event", "ProfileName", profile.title as any);
    SetAnalyticsProp(data, "event", "MainProfile", profile.master);

    return data;
}

export function getDeleteProfileActionProperties(profile: UserProfileModel) {
    const data = createAnalyticsData();

    SetFirstAnalyticsProp(data, "user", "FirstDeleteProfile", new Date());
    SetAnalyticsProp(data, "user", "LastDeleteProfile", new Date());
    IncrementAnalyticsProp(data, "user", "NumberOfDeleteProfile");

    SetAnalyticsProp(data, "event", "ProfileName", profile.title as any);

    return data;
}
