import { h } from "preact";
import "./ProfilesPage.scss";
import { useLinkAndPreviewContext, usePageContext, useSectionContext } from "components/shared/hooks";
import { PageTopBar } from "components/molecules/pageTopBar";
import { ContentType } from "models/ModelType";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { isFeatureEnabled, Feature, useTranslations, store, translate } from "global/config";
import { RootModel } from "models/app";
import { useSelector } from "react-redux";
import { changeUserProfile, hasPermissionFromToken, reloadProfiles, useDisplayLoggedIn } from "services/user";
import { navigation } from "services/navigation";
import { TextInput } from "components/atoms/controls/textInput";
import { useCallback, useState } from "preact/hooks";
import { Avatar } from "components/atoms/controls/avatar";
import { mutateAddProfile, mutateRemoveProfile, mutateUpdateProfile } from "services/backend";
import { route } from "preact-router";
import { showErrorModal2 } from "components/organisms/modal/modals";
import { ModalOpenResult } from "models/view/AppModalModel";
import { showDeleteProfileModal } from "components/organisms/modal/modals";
import { PROFILE_CREATED, PROFILE_DELETED, PROFILE_UPDATED } from "global/actions";
import { dispatch } from "global";

interface Props {
    profileId: string;
}

export const EditProfilePage = (props: any) => {
    const translations = useTranslations();

    const page = usePageContext({ type: ContentType.ProfilesPage, resource: null, root: null, done: true });

    const section = useSectionContext(ContentType.ProfilesPage, null, null, null, null);
    const link = useLinkAndPreviewContext(ContentType.ProfilesPage, null, section, null);
    link.url = navigation.templates.addProfilesPage(); // todo

    const loggedIn = useDisplayLoggedIn();
    const profiles = useSelector((root: RootModel) => root.user.profiles);
    const profilesLimit = useSelector((root: RootModel) => root.user.profilesNumberLimit) ?? 0;
    const showProfiles = loggedIn && profiles.length > 0 && hasPermissionFromToken("prof") && isFeatureEnabled(Feature.Profiles);
    const defaultColors = useSelector((root: RootModel) => root.user.profileDefaultColors);
    const actualDefaultColors = !defaultColors || defaultColors.length == 0 ? ["#FF00FF"] : defaultColors;
    const profileId = (props as Props).profileId;
    const profile = profiles.find(n => n.id == profileId) ?? null;
    const isNew = !profile;

    const [title, setTitle] = useState(profile?.title ?? "");
    const [color, setColor] = useState(profile?.color ?? "");
    const isValid = !!title;
    const isChanged = title !== profile?.title || color !== profile?.color;

    const onChangeTitle = useCallback((e: string) => {
        setTitle(e);
        if (e.trim()) {
            //   setErrorMessage("");
        }
    }, []);

    const onChangeColor = useCallback((e: string) => {
        setColor(e);
    }, []);

    const onBack = useCallback(() => {
        route(navigation.templates.profilesPage());
    }, []);

    const onDelete = useCallback(async () => {
        if (!profile || profile?.master) return;
        const modalResult = await showDeleteProfileModal(profile);
        if (modalResult !== ModalOpenResult.Submit) {
            return;
        }
        if (profile?.current) {
            const masterProfile = profiles.find(n => n.master);
            if (!masterProfile) return;
            await changeUserProfile(masterProfile.id);
        }
        const res = await mutateRemoveProfile({ profileId: profile!.id });
        await reloadProfiles();
        if (res.ok) {
            dispatch({
                type: PROFILE_DELETED,
                payload: {
                    profile
                }
            });
            onBack();
        } else {
            const stillExists = store.getState().user.profiles.some(n => n.id == profileId);
            if (stillExists) {
                // it wasn't deleted
                showErrorModal2(translate("DeleteProfileFailsTitle"), translate("DeleteProfileFailsSubTitle"));
            } else {
                // something else deleted it, so lets just quietly claim credit
                onBack();
            }
        }
    }, [profiles, profile]);

    const onSave = useCallback(async () => {
        if (isNew) {
            const res = await mutateAddProfile({ title, color: color.replace("#", "") || actualDefaultColors[0] || "FF00FF", description: null });
            await reloadProfiles();
            if (res.ok) {
                dispatch({
                    type: PROFILE_CREATED,
                    payload: {
                        profile: res.profile!
                    }
                });
                onBack();
            } else if (store.getState().user.profiles.length >= (store.getState().user.profilesNumberLimit ?? 0)) {
                // we are at the maximum number of profiles
                showErrorModal2(translate("CreateProfileFailsTitle"), translate("CreateProfileFailsLimitSubtitle"));
            } else {
                // we randomly failed
                showErrorModal2(translate("CreateProfileFailsTitle"), translate("CreateProfileFailsSubtitle"));
            }
        } else {
            const res = await mutateUpdateProfile({ profileId: profile.id, profile: { title, color: color.replace("#", ""), description: profile.description } });
            await reloadProfiles();
            if (res.ok) {
                dispatch({
                    type: PROFILE_UPDATED,
                    payload: {
                        profile: store.getState().user.profiles.find(n => n.id == profile.id)!,
                        oldProfile: profile
                    }
                });
                onBack();
            } else {
                const stillExists = store.getState().user.profiles.some(n => n.id == profileId);
                if (stillExists) {
                    // we randomly failed
                    showErrorModal2(translate("EditProfileFailsTitle"), translate("EditProfileFailsSubtitle"));
                }
                else {
                    // the profile had been deleted by someone else
                    showErrorModal2(translate("EditProfileFailsTitle"), translate("EditProfileFailsDeletedSubtitle"));
                    onBack();
                }
            }
        }
    }, [title, color, profile, actualDefaultColors]);


    if (!showProfiles) {
        route(navigation.templates.frontPage());
    }
    if (isNew && profiles.length >= profilesLimit) {
        route(navigation.templates.profilesPage());
    }

    return (
        <div className="profilesPage cl9">
            <PageTopBar page={page} back={true} disableMobileTitle middle={isNew ? translations.AddProfilePageTitle : translations.EditProfilePageTitle} />

            <div className="scroll">
                <section className={`section editprofile`}>
                    <div className="avatarholder">
                        <Avatar text={title} color={color} class="large" />
                    </div>

                    <TextInput
                        autoComplete="off"
                        autoFocus={true}
                        className={""/*className*/}
                        onChange={onChangeTitle}
                        placeholder={translations.ProfilesNameHint}
                        type="text"
                        value={title}
                    />

                    <ProfileColorSelector color={color ?? ""} colors={actualDefaultColors} onColorSelected={onChangeColor} />

                    <div className="buttonrow">
                        <Button design={ButtonDesign.SecondaryBig} onClick={onBack}>{translations.Cancel}</Button>
                        <Button design={ButtonDesign.SecondaryBig} onClick={onSave} disabled={!isValid || !isChanged}>{translations.GlobalSaveName}</Button>
                    </div>
                    {
                        profile?.master &&
                        <div className="buttonrow">{translations.PrimaryProfileCantBeDeleted}</div>
                    }
                    {
                        profile && !profile.master &&
                        <div className="buttonrow">
                            <Button design={ButtonDesign.SecondaryBig} onClick={onDelete}>{translations.Delete}</Button>
                        </div>
                    }
                </section>
            </div>
        </div>
    );
};

function ProfileColorSelector(props: { color: string, colors: string[], onColorSelected: (col: string) => void }) {
    return (
        <div className="profileColors">
            {
                props.colors.map(col => <button className={"colorselect" + (props.color == col ? " selected" : "")} style={{ backgroundColor: col }} onClick={() => props.onColorSelected(col)} />)
            }
        </div>
    );
}
