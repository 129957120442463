import { Fragment, h } from "preact";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "preact/hooks";
import "./ListView.scss";
import { getRows } from "./useRows";
import { useScrollYPosition } from "./useScrollYPosition";
import { getComparableId } from "services/resource";
import type { Multiselect } from "services/selection";
import type { DragProps, DropProps } from "models/app";
import type { SectionContextModel, PreviewContextModel } from "models/app/resourceContextModel";
import type { NotificationConversion, ResourcePreviewModel } from "models/domain";
import { ResourceDisplayType } from "models/domain";
import type { Pagination } from "components/shared/hooks/usePagination";
import { CoverSize } from "components/atoms/cover";
import type { IconName } from "components/atoms/icon";
import type { PreviewAction } from "components/molecules/preview";
import { ResourcePreviewRow } from "components/molecules/preview/rows";

type ListDisplayTypes = ResourceDisplayType.List | ResourceDisplayType.ListLarge | ResourceDisplayType.ListMedium;

interface GenericListViewProps<T> {
    totalItems?: number;
    paginationCallback?: () => void;
    pagination?: Pagination<unknown> | null;
    display: ListDisplayTypes;
    resources: T[] | null;
    mapper: (resource: T, i: number, coverSize: CoverSize, firstRowInView: number) => h.JSX.Element;
    getParent: (element: HTMLDivElement | null) => HTMLElement | null;
    isHighlight?: (id: string, index: number) => boolean;
}

export const GenericListView = <T extends { id: string },>({ totalItems, paginationCallback, pagination, display, resources, mapper, getParent, isHighlight }: GenericListViewProps<T>) => {
    const viewRef = useRef<HTMLDivElement>(null);
    const { rowHeight, coverSize } = useListSizes(display);

    const resourcesLength = resources?.length ?? 0;

    // totalrows can differ from what the connection reports, if there is an enforced max or if we unexpectedly have no next page
    const paginationLength = Math.min(totalItems ?? pagination?.max ?? pagination?.connection?.totalCount ?? 0, totalItems ?? pagination?.connection?.totalCount ?? 0);
    const totalRows = (pagination?.connection && !pagination.connection.pageInfo.hasNextPage) || (resourcesLength > paginationLength)
        ? resourcesLength
        : paginationLength;

    const { firstRowInView, lastRowInView } = getRows(viewRef.current, rowHeight);

    const topSpacerHeight = Math.max(0, rowHeight * firstRowInView);
    const bottomSpacerHeight = Math.max(0, (totalRows - lastRowInView) * rowHeight);
    const [parent, setParent] = useState<HTMLElement | null>(null);

    useLayoutEffect(() => {
        const parent = getParent(viewRef.current);
        setParent(parent);
    }, [getParent, viewRef]);

    const scrollYPosition = useScrollYPosition(parent, (n) => n - (n % rowHeight));

    const [hasAutoscrolled, setHasAutoscrolled] = useState(false);
    useEffect(() => {
        if (hasAutoscrolled || !isHighlight || !resources || !parent) return;
        const index = resources.findIndex((resource, index) => isHighlight(resource.id, index));
        if (index != -1) {
            const pixelHeight = Math.max(0, (index - 1) * rowHeight);
            parent.scrollTop = pixelHeight;
        }
        else if (pagination?.more) {
            pagination?.more?.();
        }
    }, [resources, pagination, isHighlight, hasAutoscrolled, setHasAutoscrolled, totalRows, rowHeight, parent])

    useEffect(() => {
        const requiredItemsCount = lastRowInView + 4;
        if (requiredItemsCount > resourcesLength) {
            paginationCallback?.();
            pagination?.more?.();
        }
    }, [lastRowInView, paginationCallback, pagination, resourcesLength, scrollYPosition]);

    return (
        <div className="listView pr4" ref={viewRef} style={{ height: `${totalRows * rowHeight}px` }}>
            <div className="top-spacer" style={{ height: `${topSpacerHeight}px` }} />
            {resources?.slice(firstRowInView, lastRowInView).map((resource, i) => mapper(resource, i, coverSize, firstRowInView))}
            <div className="bottom-spacer" style={{ height: `${bottomSpacerHeight}px` }} />
        </div>
    );
};

interface ResourceListViewProps {
    dragProps: DragProps;
    paginationCallback?: () => void;
    pagination?: Pagination<unknown> | null;
    display: ListDisplayTypes;
    context: SectionContextModel;
    customIcon?: IconName;
    customIconAction?: (resource: ResourcePreviewModel) => void;
    width: number | null;
    onAction?: (action: PreviewAction, preview: PreviewContextModel, conversion: NotificationConversion | null) => void;
    isHighlight?: (id: string) => boolean;
    totalItems?: number;
    resources: ResourcePreviewModel[] | null;
    getDropProps?: (model: ResourcePreviewModel, index: number) => DropProps;
    multiselect?: Multiselect;
    locked?: boolean;
}
export const ResourceListView = ({
    context,
    customIcon,
    customIconAction,
    display,
    dragProps,
    getDropProps,
    isHighlight,
    multiselect,
    onAction,
    width,
    totalItems,
    resources,
    pagination,
    paginationCallback,
    locked
}: ResourceListViewProps) => {
    const mapper = useCallback(
        (resource: ResourcePreviewModel, i: number, coverSize: CoverSize, firstRowInView: number) => {
            return (
                <ResourcePreviewRow
                    context={context}
                    customIcon={customIcon}
                    customIconAction={customIconAction}
                    disableLazyLoad
                    dragProps={dragProps}
                    dropProps={(getDropProps?.(resource, firstRowInView + i)) ?? null}
                    highlight={isHighlight?.(resource.id) ?? false}
                    key={getComparableId(resource)}
                    multiselect={multiselect}
                    onAction={onAction}
                    rank={firstRowInView + i}
                    resource={resource}
                    size={coverSize}
                    width={width}
                    locked={locked}
                />
            );
        },
        [context, customIcon, customIconAction, dragProps, getDropProps, isHighlight, multiselect, onAction, width, locked],
    );
    const getParent = useCallback((current: HTMLDivElement | null) => current?.parentElement?.parentElement?.parentElement ?? null, []);

    return <GenericListView resources={resources} paginationCallback={paginationCallback} pagination={pagination} display={display} mapper={mapper} getParent={getParent} totalItems={totalItems} isHighlight={isHighlight} />;
};

interface QueueListViewProps<T> {
    resources: T[];
    mapper: (track: T, index: number) => h.JSX.Element;
}
export const QueueListView = <T extends { id: string },>({ resources, mapper }: QueueListViewProps<T>) => {
    const getParent = useCallback((current: HTMLDivElement | null) => current?.parentElement?.parentElement ?? null, []);

    return <GenericListView resources={resources} display={ResourceDisplayType.List} mapper={mapper} getParent={getParent} />;
};

function useListSizes(display: ListDisplayTypes) {
    switch (display) {
        case ResourceDisplayType.List:
            return { rowHeight: 64, coverSize: CoverSize.Size48 };
        case ResourceDisplayType.ListMedium:
            return { rowHeight: 80, coverSize: CoverSize.Size64 };
        case ResourceDisplayType.ListLarge:
            return { rowHeight: 104, coverSize: CoverSize.Size80 };
    }
}
