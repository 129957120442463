import { h } from "preact";
import type { Ref } from "preact/hooks";
import { useCallback, useMemo } from "preact/hooks";
import { ResourceSectionScroller } from "./ResourceSectionScroller";
import { GridView } from "../recyclerView/GridView";
import { ResourceListView } from "../recyclerView/ListView";
import { log, DefaultLogMessage } from "services/logger/initLoggerService";
import type { Multiselect } from "services/selection";
import { getNextUUID } from "services/utils";
import type { DragProps, DropProps } from "models/app";
import type { PreviewContextModel, SectionContextModel } from "models/app/resourceContextModel";
import type { ResourcePreviewModel } from "models/domain";
import { ResourceDisplayType } from "models/domain";
import type { NotificationConversion } from "models/domain/NotificationModel";
import type { Pagination } from "components/shared/hooks/usePagination";
import type { IconName } from "components/atoms/icon";
import type { PreviewAction } from "components/molecules/preview";
import { BannerSingle, PreviewSingle } from "components/molecules/preview/singles";
import type { ScrollerNavigateCallback, ScrollerNavigatePropetiesCallback } from "components/organisms/scroller/options";

interface ContentProps {
    context: SectionContextModel;
    customIcon?: IconName;
    customIconAction?: (resource: ResourcePreviewModel) => void;
    displayType: ResourceDisplayType;
    onAction?: (action: PreviewAction, preview: PreviewContextModel, conversion: NotificationConversion | null) => void;
    onScrollerNavigatePropertiesChange: ScrollerNavigatePropetiesCallback;
    pagination: Pagination<unknown> | null;
    paginationCallback?: () => void;
    totalItems?: number;
    resources: ResourcePreviewModel[] | null;
    scrollerNavigateRef: Ref<ScrollerNavigateCallback | null>;
    width: number | null;
    getDropProps?: (model: ResourcePreviewModel, index: number) => DropProps;
    highlightId?: string;
    multiselect?: Multiselect;
    locked?: boolean;
}
export const Content = ({
    context,
    customIcon,
    customIconAction,
    displayType,
    onAction: onActionProp,
    onScrollerNavigatePropertiesChange,
    pagination,
    paginationCallback,
    totalItems,
    resources,
    scrollerNavigateRef,
    width,
    getDropProps,
    highlightId,
    multiselect,
    locked
}: ContentProps) => {
    const dragProps: DragProps = useMemo(() => ({ dragSourceId: getNextUUID() }), []);
    const onAction = useCallback(
        (action: PreviewAction, preview: PreviewContextModel, conversion: NotificationConversion | null) => {
            onActionProp && onActionProp(action, preview, conversion);
        },
        [onActionProp]
    );

    const isHighlight = useMemo(() => !highlightId ? undefined : ((id: string) => id === highlightId), [highlightId]);

    switch (displayType) {
        case ResourceDisplayType.Scroller:
        case ResourceDisplayType.LargeScroller: {
            return (
                <ResourceSectionScroller
                    dragProps={dragProps}
                    resources={resources}
                    isHighlight={isHighlight}
                    context={context}
                    onAction={onAction}
                    getDropProps={getDropProps}
                    displayType={displayType}
                    section={context}
                    navigateRef={scrollerNavigateRef}
                    onNavigatePropertiesChange={onScrollerNavigatePropertiesChange}
                />
            );
        }
        case ResourceDisplayType.List:
        case ResourceDisplayType.ListMedium:
        case ResourceDisplayType.ListLarge: {
            return (
                <ResourceListView
                    dragProps={dragProps}
                    resources={resources}
                    isHighlight={isHighlight}
                    context={context}
                    onAction={onAction}
                    pagination={pagination}
                    paginationCallback={paginationCallback}
                    totalItems={totalItems}
                    display={displayType}
                    getDropProps={getDropProps}
                    width={width}
                    multiselect={multiselect}
                    customIconAction={customIconAction}
                    customIcon={customIcon}
                    locked={locked}
                />
            );
        }
        case ResourceDisplayType.Grid: {
            return <GridView dragProps={dragProps} resources={resources} isHighlight={isHighlight} context={context} onAction={onAction} pagination={pagination} />;
        }
        case ResourceDisplayType.Single: {
            const resource = resources ? resources[0] : null;

            if (!resource) return null;
            if (resources?.length !== 1) log.error({ code: "web-210208-1213", msg: "single resources length !== 1" });

            return (
                <PreviewSingle highlight={isHighlight?.(resource.id) ?? false} resourceType={resource.contentType} section={context}>
                    {resource}
                </PreviewSingle>
            );
        }
        case ResourceDisplayType.LargeSingle:
            log.info({ code: "web-220216-1405", msg: DefaultLogMessage.NotImplemented, data: { displayType } });
            return null;
        case ResourceDisplayType.PromoSingle: {
            const resource = resources ? resources[0] : null;
            if (!resource) return null;
            if (resource.contentType !== "BannerLayoutItemContent") return null;
            return (
                <BannerSingle highlight={isHighlight?.(resource.id) ?? false} resourceType={resource.contentType} section={context}>
                    {resource}
                </BannerSingle>
            );
        }
        case ResourceDisplayType.Top:
        case ResourceDisplayType.Unknown:
        case ResourceDisplayType.Deeplink:
            log.error({ code: "web-220216-1402", msg: DefaultLogMessage.NotImplemented, data: { displayType } });
            return null;
    }
};
